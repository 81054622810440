<template>
    <div class="div review-div">
        <template v-for="item in indexList">
            <div class="review-top-list" @click="reviewClick(item)">
                <div class="review">
                    <div class="review-top">
                        <p class="review-title">{{item.send_nickname}}</p>
                        <p class="review-subtitle">{{item.time_detail}}</p>
                    </div>
                    <p>
                        <img :src="item.send_avatar" alt="" class="set_def_tx">
                    </p>
                </div>
                <p class="review-text">
                    {{item.summary}}
                </p>
                <div class="review-button">
                    <p class="review-button-title">来自:《{{item.rel_title}}》</p>
                    <p class="review-button-right">
                        <img src="../../assets/images/icon.png" alt="" class="icon"> {{item.content.likes}}
                    </p>
                </div>
            </div>
        </template>


    </div>
</template>

<script>
    export default {
        name: "BookReview",
        props:{
            indexList:Array,
        },
        data() {
            return {
            }
        },
        methods: {
            reviewClick(item) {
                this.$emit('reviewClick', item)
            },

        }
    }
</script>

<style scoped>
    .review-div {
        margin-bottom: 60px;
    }

    .review-top-list {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .review-button-title {
        font-size: 22px;
        color: #BFC2CC;
    }

    .review-button-right {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #BFC2CC;
    }

    .review-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 27px;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .review-subtitle {
        font-size: 22px;
        color: #BFC2CC;
        margin-top: 20px;
    }

    .review-title {
        color: #909399;
        font-size: 26px;
    }

    .review-text {
        font-size: 28px;
        color: #303133;
        line-height: 40px;
        text-align: justify;
    }

    .review {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .set_def_tx {
        width: 60px;
        height: 60px;
        background: url("../../assets/images/set_def_tx.png") no-repeat;
        background-size: cover;
        border-radius: 100%;
    }

    .review-top {
        padding: 40px 0 30px;
    }
</style>