<template>
    <div>
        <template v-for="item in indexList">
            <div class="system-list">
                <div class="system-top-list">
                    <div class="system-top">
                        <p class="system-title" :class="item.read_status =='0'?'system-title_active':''">
                            {{item.title}}</p>
                        <p class="system-time">{{item.time_detail}}</p>
                    </div>
                    <p class="system-text">
                        {{item.summary}}
                    </p>
                </div>
                <div class="system-button" @click="systemdetailsClick(item)">
                    <p class="system-button-title">查看详情</p>
                    <p>
                        <van-icon name="arrow" color="#BFC2CC" size="15"/>
                    </p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: "System",
        props: {
            indexList: Array,
        },
        methods: {
            systemdetailsClick(item) {
                this.$emit('systemdetailsClick',item)
            }
        }
    }
</script>

<style scoped>
    .system-button-title {
        color: #909399;
        font-size: 24px;
    }

    .system-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;
    }

    .system-text {
        color: #606266;
        font-size: 28px;
        line-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /*决定哪一行截取*/
        -webkit-box-orient: vertical;
        margin-top: 30px;
        text-align: justify;
    }

    .system-time {
        color: #BFC2CC;
        font-size: 22px;
    }

    .system-title {
        font-size: 30px;
        color: #0A1C33;
    }

    .system-title_active {
        position: relative;
    }

    .system-title_active:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: red;
        position: absolute;
        left: -10px;
        top: 0;
    }

    .system-top-list {
        border-bottom: 1px solid #F2F5F8;
        padding: 40px;
    }

    .system-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .system-list {
        box-shadow: 0px 0px 18px 0px rgba(132, 134, 156, 0.1);
        border-radius: 10px;
        margin: 40px;
    }
</style>