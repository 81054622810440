<template>
    <div class="div Praise-div">
        <template v-for="item in indexList">
            <div class="Praise" @click="touchstartClick(item)">
                <p><img :src="item.send_avatar" alt="" class="set_def_tx"></p>
                <div class="Praise-right">
                    <div class="Praise-right-top">
                        <div class="book">
                            <p class="Praise-right-title">{{item.send_nickname}}</p>
                            <p class="Praise-right-subtitle">{{item.at_alt}}</p>
                        </div>
                        <p class="Praise-right-time">{{item.time_detail}}</p>
                    </div>
                    <div class="book-text-div">
                        <p class="book-text">
                            {{item.content.like_comment_content}}
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: "Praise",
        props: {
            indexList: Array,
        },
        data() {
            return {}
        },
        methods: {
            touchstartClick(item) {
                this.$emit('touchstartClick', item)
            }
        }
    }
</script>

<style scoped>
    .Praise-div {
        margin-bottom: 60px;
    }

    .book-text-div {
        background: #F2F5F8;
        padding: 30px;
        margin-top: 30px;
        border-radius: 4px;
    }

    .book-text {
        color: #303133;
        font-size: 28px;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /*决定哪一行截取*/
        -webkit-box-orient: vertical;

    }

    .book {
        display: flex;
        align-items: center;
    }

    .Praise-right-time {
        color: #BFC2CC;
        font-size: 22px;
    }

    .Praise-right-subtitle {
        font-size: 26px;
        color: #909399;
    }

    .Praise-right-title {
        color: #FCAD0F;
        font-size: 26px;
        margin-right: 18px;
    }

    .Praise-right-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .Praise {
        display: flex;
        margin-top: 60px;
    }

    .Praise-right {
        width: 100%;
        margin-left: 23px;
    }

    .set_def_tx {
        height: 60px;
        width: 60px;
        border-radius: 100%;
        display: inline-block;
        background: url("../../assets/images/set_def_tx.png") no-repeat;
        background-size: cover;
    }
</style>