<template>
    <div class="more">
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="我的消息"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div>
            <van-tabs
                    v-model="active"
                    animated
                    swipeable
                    :border="false"
                    title-active-color="#4D77FD"
                    title-inactive-color="#333333"
                    @change='changeClick'
            >

                <template v-for="item in Tabs">
                    <van-tab :title="item">

                        <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    :finished="finished"
                                    :immediate-check="false"
                                    :finished-text="finished_text"
                                    @load="onLoad"
                            >
                                <div class="wel" v-if="loading_show">
                                    <van-loading size="20px">加载中...</van-loading>
                                </div>
                                <div v-if="indexList">
                                    <div v-if="active == 0">
                                        <SystemView @systemdetailsClick="systemdetailsClick"
                                                    :indexList="indexList"></SystemView>
                                    </div>
                                    <div v-if="active == 1">
                                        <Praise @touchstartClick="touchstartClick" :indexList="indexList"></Praise>
                                    </div>
                                    <div v-if="active == 2">
                                        <BookReview @reviewClick="reviewClick" :indexList="indexList"></BookReview>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                        <div class="no" v-if="indexList.length == 0 && !loading_show">
                            <No title="暂无消息"></No>
                        </div>
                    </van-tab>
                </template>
            </van-tabs>
        </div>
        <van-action-sheet
                v-model="show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancel"
                @select="selectClick"
        />
    </div>
</template>

<script>
    import SystemView from '../../components/MyView/SystemView'
    import Praise from '../../components/MyView/Praise'
    import BookReview from '../../components/MyView/BookReview'
    import No from "../../components/No";

    export default {
        components: {
            SystemView,
            Praise,
            BookReview,
            No,
        },
        name: "System",
        data() {
            return {
                active: '0',
                Tabs: ['系统消息', '收到的赞', '我的书评'],
                show: false,
                actions: [{id: "0", name: '查看详情'}, {id: "1", name: '删除', color: '#D53D3C'},],
                Time: {},
                cat_type: '',
                type: '',
                page: 1,
                page_size: 10,
                indexList: [],
                loading_show: true,
                moreList_show: false,
                refreshing: false,
                loading: false,
                finished: false,
                finished_text: ''
            }
        },
        created() {
            this.active = Number(this.$route.query.active)
            if (!this.active) {
                this.cat_type = 'system'
                this.type = 'notice'
            } else {
                this.cat_type = 'comment'
                this.type = 'comment'
            }
            this.indexGet()
        },
        methods: {

            indexGet() {
                this.$axios.post('/api/message/index', {
                    cat_type: this.cat_type,
                    type: this.type,
                    page: this.page,
                    page_size: this.page_size,
                })
                    .then(res => {
                        this.loading_show = false
                        var res = res.data.data
                        if (res.length < this.page_size) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                        if (this.page == 1) {
                            this.finished_text = ''
                        }

                        this.refreshing = false
                        this.loading = false;
                        this.listList = res
                        if (this.moreList_show) {
                            this.indexList = res
                            this.moreList_show = false
                        } else {
                            this.indexList = this.indexList.concat(res)
                        }
                    })
            },

            onRefresh() {
                //下拉刷新
                this.page = 1
                if (this.indexList.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.moreList_show = true
                this.indexGet()
            },
            onLoad() {
                if (this.finished) return
                this.page++;
                this.indexGet();
            },
            systemdetailsClick(item) {
                this.$router.push({
                    path: '/systemdetails',
                    query: {
                        type: item,
                    },
                })
            },
            changeClick() {
                this.loading_show = true
                this.page = 1
                this.indexList = []
                this.moreList_show = true
                if (this.active == 0) {
                    this.cat_type = 'system';
                    this.type = 'notice';
                }
                if (this.active == 1) {
                    this.cat_type = 'like';
                    this.type = 'comment_like';
                }
                if (this.active == 2) {
                    this.cat_type = 'comment';
                    this.type = 'comment';
                }
                this.indexGet()
            },
            touchstartClick(item) {
                this.Time = item
                this.show = true
            },
            reviewClick(item) {
                this.Time = item
                this.show = true
            },
            onCancel() {
                this.show = false
            },
            selectClick(item) {
                if (item.id == '0') {
                    this.$router.push({
                        path: '/bookdetails',
                        query: {
                            id: this.Time.comment_id
                        }
                    })
                } else {
                    this.$dialog.alert({
                        title: '删除',
                        message: '确定删除评论？',
                        confirmButtonText: '删除',
                        confirmButtonColor: "#D53D3C",
                        showCancelButton: 'true',
                    })
                        .then((res) => {
                            this.$axios.post('/api/message/delete', {
                                id: this.Time.id,
                                type: this.Time.type,
                            })
                                .then(res => {
                                    this.$toast(res.data.message)
                                    this.indexGet()
                                })
                        })
                }
                this.show = false
            },

        }
    }
</script>

<style scoped>

</style>